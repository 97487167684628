<template>
  <div class="card card-button" :class="`card-${theme}`" @click="goTo">
    <div class="card-header" v-if="title">
      <h6>{{ title }}</h6>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "dark",
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },
  methods: {
    goTo() {
      if (!this.to) return;

      if (typeof this.to === "string" && this.to.slice(0, 4) === "http") {
        window.open(this.to);
      } else if (this.to[0] === "#") {
        this.scrollToTag(this.to);
      } else {
        this.$router.push(this.to);
      }
    },
    scrollToTag(tag) {
      console.log(tag.slice(1));
      var element = document.getElementById(tag.slice(1));
      element.scrollIntoView();
    },
  },
};
</script>

<style></style>

<template>
  <ImageContainer class="img-page-header">
    <div class="caption">
      <div class="container">
        <slot />
      </div>
    </div>
  </ImageContainer>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  components: {
    ImageContainer,
  },
};
</script>

<style></style>

<template>
  <ImageContainer
    class="img-section-header contact-section"
    :style="`--size: ${size}px`"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="d-flex flex-column h-100 gap-4 pb-6 pb-md-0">
            <h4>{{ $lang("contact_form.title", true) }}</h4>

            <p class="mb-auto h6">{{ $lang("contact_form.subtitle", true) }}</p>
            <p class="mb-0 h6">
              <b>
                {{ $lang("contact_form.accredited", true) }}<br />
                {{ $lang("contact_form.accredited2", true) }}
              </b>
            </p>
            <div class="row">
              <div class="col-5 pr-0">
                <img
                  src="/imgs/nl_college_white.png"
                  alt="NL College"
                  class="img-fluid"
                />
              </div>
              <div class="col-3">
                <a href="https://acreditacion.cervantes.es/" target="_blank">
                  <img
                    src="/imgs/cervantes_white.png"
                    alt="Cervantes Institute"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
            <small><slot /></small>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 offset-lg-3">
          <ContactForm :theme="theme" />
        </div>
      </div>
    </div>
  </ImageContainer>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";
import ContactForm from "./ContactForm.vue";

export default {
  props: {
    oldSource: {
      type: String,
      default: null,
    },
    newSource: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 600,
    },
    theme: {
      type: String,
      default: "secondary",
    },
  },
  components: {
    ImageContainer,
    ContactForm,
  },
};
</script>
